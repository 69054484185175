import { NextPage } from 'next'
import Head from 'next/head'
import { useTranslation } from 'react-i18next'
import Footer from '../Footer'
import Header from '../Header'
import { Banner, Benefits, Docs, Features, News, Services } from './components'
import {
  StyledHome,
  StyledHomeHero,
  StyledHomeHeroImageRow,
  StyledHomeHeroImageRowLast,
} from './styled'
import HeroLogo from '~/assets/images/hero-logo.svg'
import HeroPuzzle from '~/assets/images/hero-puzzle.svg'
import HeroCode from '~/assets/images/hero-code.svg'
import HeroCube from '~/assets/images/hero-cube.svg'

const Home: NextPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Head>
        <title>{t('meta_common_title')}</title>
        <meta name="description" content={t('meta_common_description')} />
        <meta name="keywords" content={t('meta_common_keywords')} />
      </Head>
      <StyledHome>
        <Header />
        <StyledHomeHero>
          {/* @todo: replace by StyledHomeBGIconsContainer? */}
          <StyledHomeHeroImageRow>
            <img src={HeroPuzzle} className="puzzle" alt="Icon" />
            <img src={HeroLogo} className="logo" alt="Icon" />
          </StyledHomeHeroImageRow>
          <StyledHomeHeroImageRowLast>
            <img src={HeroCube} className="cube" alt="Icon" />
            <img src={HeroCode} className="code" alt="Icon" />
          </StyledHomeHeroImageRowLast>
          <Banner />
        </StyledHomeHero>
        <Features />
        <News />
        <Benefits />
        <Services />
        <Docs />
        <Footer />
      </StyledHome>
    </>
  )
}

export default Home
