import Home from '~/components/Home'
import i18n from '~/i18n'
import { HOST } from '~/constants/host'

export const getStaticProps = async () => {
  const t = i18n.t

  return {
    props: {
      openGraphData: [
        {
          property: 'og:url',
          content: `${HOST}/`,
          key: 'ogurl',
        },
        {
          property: 'og:title',
          content: t('meta_common_title'),
          key: 'ogtitle',
        },
        {
          property: 'og:description',
          content: t('meta_common_description'),
          key: 'ogdesc',
        },
      ],
    },
  }
}

export default Home
