import { useTranslation } from 'react-i18next'
import {
  StyledHomeContent,
  StyledHomeTitle,
  StyledDocs,
  StyledDocsItem,
  StyledDocsContent,
} from '../styled'
import DocSDK from '~/assets/images/doc-sdk.svg'
import DocMarket from '~/assets/images/doc-market.svg'
import DocSmartContract from '~/assets/images/doc-smart-contract.svg'
import trace from '~/ga'
import { useBreakpoint, useGA } from '~/hooks'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { CSSProperties } from 'react'
import { LOCALES } from '~/locales'

export const Docs: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { isBreakPointLG } = useBreakpoint()
  const { trackElClick } = useGA()

  const isZh = i18n.resolvedLanguage === LOCALES.ZH
  const lang = isZh ? '' : 'en'

  const indicatorStyles: CSSProperties = {
    display: 'inline-block',
    width: 28,
    height: 28,
    borderRadius: '50%',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#FFFFFF',
    background: '#D1D1E1',
  }

  const docs = [
    {
      icon: DocSmartContract,
      link: 'https://github.com/consenlabs/tokenlon-contracts',
    },
    {
      icon: DocSDK,
      link: 'https://tokenlon-v5-sdk.docs.imvercel.works/',
    },
    {
      icon: DocMarket,
      link: `https://docs.token.im/tokenlon-onboarding/${lang}`,
    },
  ]

  return (
    <StyledDocs>
      <StyledHomeContent>
        <StyledHomeTitle>{t('docs_title')}</StyledHomeTitle>
        {!isBreakPointLG ? (
          <StyledDocsContent>
            {docs.map(({ icon, link }, index) => (
              <StyledDocsItem
                key={index}
                href={link}
                target="_blank"
                data-trackid="webtl_homepage_developerbanner"
                data-trackdata={JSON.stringify({
                  title: t(`doc_${index + 1}_title`),
                  url: link,
                  index,
                })}
                onClick={trackElClick}
              >
                <img src={icon} alt="docs icon" />
                <div>
                  <h3 className="item-title">{t(`doc${index + 1}_title`)}</h3>
                  <p className="item-desc">{t(`doc${index + 1}_desc`)}</p>
                </div>
              </StyledDocsItem>
            ))}
          </StyledDocsContent>
        ) : (
          <Carousel
            showThumbs={false}
            axis="horizontal"
            showIndicators={true}
            showStatus={false}
            showArrows={false}
            autoPlay={false}
            emulateTouch
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    style={{ ...indicatorStyles, background: '#1C1C1E' }}
                    aria-label={`Selected: ${label} ${index + 1}`}
                    title={`Selected: ${label} ${index + 1}`}
                  >
                    {index + 1}
                  </li>
                )
              }
              return (
                <li
                  style={indicatorStyles}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
                >
                  {index + 1}
                </li>
              )
            }}
          >
            {docs.map(({ icon, link }, index) => (
              <StyledDocsItem
                key={index}
                href={link}
                target="_blank"
                data-trackid="webtl_homepage_developerbanner"
                data-trackdata={JSON.stringify({
                  title: t(`doc_${index + 1}_title`),
                  url: link,
                  index,
                })}
                onClick={trackElClick}
              >
                <img src={icon} alt="docs icon" />
                <div>
                  <h3 className="item-title">{t(`doc${index + 1}_title`)}</h3>
                  <p className="item-desc">{t(`doc${index + 1}_desc`)}</p>
                </div>
              </StyledDocsItem>
            ))}
          </Carousel>
        )}
      </StyledHomeContent>
    </StyledDocs>
  )
}
