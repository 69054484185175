export const insertLink = (rawText, reg, link) => {
  const matches = rawText.match(reg)
  if (matches && matches.length) {
    const others = rawText.split(matches[0])
    return (
      <span>
        {others[0]}
        <a href={link}>{matches[0]}</a>
        {others[1]}
      </span>
    )
  }
  return rawText
}
