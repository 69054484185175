import { useTranslation } from 'react-i18next'
import {
  StyledBanner,
  StyledHomeContent,
  StyledBannerTitle,
  StyledHomeSubTitle,
  StyledHomeInstantButton,
  StyledHomeDataGrid,
  StyledHomeDataPanel,
} from '../styled'
import { useGA, useTxDataSummary, useBreakpoint } from '~/hooks'
import { formatPercentage, formatCountUnit } from '~/utils/numbers'
// import { LOCALES } from '~/locales'
// import BtnDesktopBgZH from '~/assets/images/launch-dapp-desktop-bg-zh.svg'
// import BtnDesktopBgEN from '~/assets/images/launch-dapp-desktop-bg-en.svg'
// import BtnMobileBgZH from '~/assets/images/launch-dapp-mobile-bg-zh.svg'
// import BtnMobileBgEN from '~/assets/images/launch-dapp-mobile-bg-en.svg'
import ClickHand from '~/assets/images/click-hand.svg'

export const Banner: React.FC = () => {
  const { t, i18n } = useTranslation()
  // const { isMobile } = useBreakpoint()
  const { trackElClick } = useGA()
  const {
    successRate,
    totalTxs,
    totalVolume,
    totalWallets,
  } = useTxDataSummary()

  // const isZh = i18n.resolvedLanguage === LOCALES.ZH
  // const btnBg = isMobile
  //   ? isZh
  //     ? BtnMobileBgZH
  //     : BtnMobileBgEN
  //   : isZh
  //   ? BtnDesktopBgZH
  //   : BtnDesktopBgEN

  return (
    <StyledBanner>
      <StyledHomeContent>
        <StyledBannerTitle>
          <p>{t('slogan_1')}</p>
          <p className="gradient-text">{t('slogan_2')}</p>
        </StyledBannerTitle>
        <StyledHomeSubTitle>{t('slogan_desc')}</StyledHomeSubTitle>
        <StyledHomeInstantButton
          href="/instant"
          data-trackid="webtl_startinstant"
          data-trackdata={JSON.stringify({ from: 'hp_top' })}
          onClick={trackElClick}
        >
          <img src={ClickHand} alt="click" />
          <span>{t('launch_dapp')}</span>
        </StyledHomeInstantButton>

        <StyledHomeDataGrid>
          <StyledHomeDataPanel>
            <h3 className="value">{`$${formatCountUnit(totalVolume)}+`}</h3>
            <p className="label">{t('total_volume')}</p>
          </StyledHomeDataPanel>
          <StyledHomeDataPanel>
            <h3 className="value">{`${formatCountUnit(totalTxs)}+`}</h3>
            <p className="label">{t('total_transactions')}</p>
          </StyledHomeDataPanel>
          <StyledHomeDataPanel>
            <h3 className="value">{formatCountUnit(totalWallets)}</h3>
            <p className="label">{t('total_wallets')}</p>
          </StyledHomeDataPanel>
          <StyledHomeDataPanel>
            <h3 className="value">{formatPercentage(successRate, 2)}</h3>
            <p className="label">{t('total_order_success_rate')}</p>
          </StyledHomeDataPanel>
        </StyledHomeDataGrid>
      </StyledHomeContent>
    </StyledBanner>
  )
}
