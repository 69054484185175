import {
  StyledServices,
  StyledHomeContent,
  StyledHomeTitle,
  StyledServicesContent,
  StyledServicesItem,
} from '../styled'
import ServiceLimitOrder from '~/assets/images/service-limitorder.svg'
import ServiceTokenlomics from '~/assets/images/service-tokenlomics.svg'
import ServiceWallet from '~/assets/images/service-wallet.svg'
import { useTranslation } from 'react-i18next'
import { ASSET_PREFIX } from '~/constants/env'
import { LOCALES } from '~/locales'
import { useGA } from '~/hooks'

export const Services: React.FC = () => {
  const { t, i18n } = useTranslation()
  const { trackElClick } = useGA()

  const isZh = i18n.resolvedLanguage === LOCALES.ZH
  const lang = isZh ? 'zh-cn' : 'en-us'

  const targetLitepaper = `${ASSET_PREFIX}/files/${
    isZh ? 'Tokenlon-Litepaper_zh-cn' : 'Tokenlon-litepaper_en-us'
  }.pdf`

  return (
    <StyledServices>
      <StyledHomeContent>
        <StyledHomeTitle>{t('services_title')}</StyledHomeTitle>

        <StyledServicesContent>
          <StyledServicesItem>
            <div className="main">
              <h3>{t('service1_title')}</h3>
              <p>{t('service1_desc')}</p>
              <a
                href={`https://support.tokenlon.im/hc/${lang}/articles/7463636612116`}
                data-trackid="webtl_homepage_servicebanner"
                data-trackdata={JSON.stringify({
                  title: t('service1_link'),
                  url: `https://support.tokenlon.im/hc/${lang}/articles/7463636612116`,
                  index: 0,
                })}
                onClick={trackElClick}
              >
                {t('service1_link')} →
              </a>
            </div>
            <div className="placeholder" />
            <img src={ServiceLimitOrder} className="layer2" alt="Layer2" />
          </StyledServicesItem>
          <StyledServicesItem>
            <h3>{t('service2_title')}</h3>
            <p>{t('service2_desc')}</p>
            <a
              href="/lon"
              style={{ marginRight: '24px' }}
              data-trackid="webtl_homepage_servicebanner"
              data-trackdata={JSON.stringify({
                title: t('service1_link'),
                url: '/lon',
                index: 1,
              })}
              onClick={trackElClick}
            >
              {t('service2_link')} →
            </a>
            <a
              href={targetLitepaper}
              data-trackid="webtl_homepage_servicebanner"
              data-trackdata={JSON.stringify({
                title: t('service2_link_2'),
                url: targetLitepaper,
                index: 2,
              })}
              onClick={trackElClick}
            >
              {t('service2_link_2')} →
            </a>
            <img
              src={ServiceTokenlomics}
              className="tokenlomics"
              alt="Tokenlomics"
            />
          </StyledServicesItem>
          <StyledServicesItem>
            <h3>{t('service3_title')}</h3>
            <p>{t('service3_desc')}</p>
            <a
              href="https://token.im/"
              data-trackid="webtl_homepage_servicebanner"
              data-trackdata={JSON.stringify({
                title: t('service3_link'),
                url: 'https://token.im/',
                index: 4,
              })}
              onClick={trackElClick}
            >
              {t('service3_link')} →
            </a>
            <img src={ServiceWallet} className="wallet" alt="Wallet" />
          </StyledServicesItem>
        </StyledServicesContent>
      </StyledHomeContent>
    </StyledServices>
  )
}
