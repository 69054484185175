import {
  StyledBenefits,
  StyledHomeContent,
  StyledHomeTitle,
  StyledBenefitsContent,
  StyledBenefitsItem,
} from '../styled'
import { useTranslation } from 'react-i18next'
import { insertLink } from '~/utils/insertLink'
import BenefitFigure1 from '~/assets/images/benefit-gasless-trading.svg'
import BenefitFigure2 from '~/assets/images/benefit-trade-price.svg'
import BenefitFigure3 from '~/assets/images/benefit-security.svg'
import BenefitFigure4 from '~/assets/images/benefit-success-rate.svg'
import BenefitFigure5 from '~/assets/images/benefit-reward.svg'
import BenefitFigure6 from '~/assets/images/benefit-customer-support.svg'

const BenefitsFigures = [
  BenefitFigure1,
  BenefitFigure2,
  BenefitFigure3,
  BenefitFigure4,
  BenefitFigure5,
  BenefitFigure6,
]

export const Benefits: React.FC = () => {
  const { t } = useTranslation()

  return (
    <StyledBenefits>
      <StyledHomeContent>
        <StyledHomeTitle>{t('benefits_title')}</StyledHomeTitle>

        <StyledBenefitsContent>
          {BenefitsFigures.map((figure, index) => {
            const idx = index + 1
            const desc =
              idx === 6
                ? insertLink(
                    t(`benefit${idx}_desc`),
                    /support@tokenlon.im/g,
                    'mailto:support@tokenlon.im',
                  )
                : t(`benefit${idx}_desc`)
            return (
              <StyledBenefitsItem key={idx}>
                <img src={figure} alt={`benefit${idx}_title`} />
                <div>
                  <h3 className="item-title">{t(`benefit${idx}_title`)}</h3>
                  <p className="item-desc">{desc}</p>
                </div>
              </StyledBenefitsItem>
            )
          })}
        </StyledBenefitsContent>
      </StyledHomeContent>
    </StyledBenefits>
  )
}
