import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useNews, useGA } from '~/hooks'
import {
  StyledHomeContent,
  StyledHomeNews,
  StyledHomeNewsHeader,
  StyledHomeNewsTitle,
  StyledHomeNewsContent,
  StyledHomeNewsItem,
} from '../styled'
import { useBreakpoint } from '~/hooks'

export const News: React.FC = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.resolvedLanguage === 'zh' ? 'zh-cn' : 'en-us'
  const news = useNews()
  const { isBreakPointLG } = useBreakpoint()
  const { trackElClick } = useGA()

  if (!news?.length) {
    return null
  }

  const lastNews = isBreakPointLG ? news.splice(0, 1) : news

  return (
    <StyledHomeNews>
      <StyledHomeContent>
        <StyledHomeNewsHeader>
          <StyledHomeNewsTitle>{t('latest_news')}</StyledHomeNewsTitle>
          <a
            className="help-center"
            href={`https://tokenlon.zendesk.com/hc/${lang}/sections/360006093572`}
          >
            <span data-trackid="webtl_homepage_newsmore" onClick={trackElClick}>
              {t('view_all')} →
            </span>
          </a>
        </StyledHomeNewsHeader>

        <StyledHomeNewsContent>
          {lastNews?.map((news, index) => {
            const showContent = !isBreakPointLG && index === 0
            return (
              <StyledHomeNewsItem
                className={showContent ? 'first' : ''}
                key={news.id}
                href={news.html_url}
                data-trackid="webtl_homepage_newsbanner"
                data-trackdata={JSON.stringify({
                  title: news.title,
                  url: news.html_url,
                  index,
                })}
                onClick={trackElClick}
              >
                <img src={news.thumbnail} alt={news.title} />
                <p className="news-title">{news.title}</p>
                <p className="news-date">
                  {dayjs(news.edited_at).format('MM/DD/YYYY')}
                </p>
                {showContent && <p className="news-digest">{news.digest}</p>}
              </StyledHomeNewsItem>
            )
          })}
        </StyledHomeNewsContent>
      </StyledHomeContent>
    </StyledHomeNews>
  )
}
