import {
  StyledFeatures,
  StyledHomeContent,
  StyledHomeTitle,
  StyledFeaturesContent,
  StyledFeaturesItem,
  StyledFeaturesItemButton,
} from '../styled'
import { useTranslation } from 'react-i18next'
import { useBreakpoint, useGA } from '~/hooks'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import FeatureFigure1 from '~/assets/images/feature-1.svg'
import FeatureFigure2 from '~/assets/images/feature-2.svg'
import FeatureFigure3 from '~/assets/images/feature-3.svg'
import EthereumLogo from '~/assets/images/ethereum-logo.svg'
import ArbitrumLogo from '~/assets/images/arbitrum-logo.svg'
import { CSSProperties } from 'react'

const features = [
  {
    link: '/instant',
    figure: FeatureFigure1,
    itemBGColor: '#EAE8FF',
    borderColor: '#DFD5FF',
    buttonColor: '#5F4DBC',
    buttonHoverColor: '#775DF8',
    subtitleColor: '#6042F1',
    logos: [EthereumLogo],
  },
  {
    link: '/limit-order',
    figure: FeatureFigure2,
    itemBGColor: '#E7F7FF',
    borderColor: '#A7E3FF',
    buttonColor: '#457CFF',
    buttonHoverColor: '#6592FF',
    subtitleColor: '#457CFF',
    logos: [EthereumLogo, ArbitrumLogo],
  },
  {
    link: '/lon/staking',
    figure: FeatureFigure3,
    itemBGColor: '#E7F7FF',
    borderColor: '#A7E3FF',
    buttonColor: '#457CFF',
    buttonHoverColor: '#6592FF',
  },
]

export const Features: React.FC = () => {
  const { t } = useTranslation()
  const { isBreakPointLG } = useBreakpoint()
  const { trackElClick } = useGA()

  const indicatorStyles: CSSProperties = {
    display: 'inline-block',
    width: 28,
    height: 28,
    borderRadius: '50%',
    fontSize: '16px',
    lineHeight: '28px',
    color: '#FFFFFF',
    background: '#D1D1E1',
  }

  return (
    <StyledFeatures>
      <StyledHomeContent>
        <StyledHomeTitle>{t('feature_title')}</StyledHomeTitle>

        {!isBreakPointLG ? (
          <StyledFeaturesContent>
            {features.map((feature, index) => {
              const idx = index + 1
              const needShowLogo = idx === 1 || idx === 2
              const {
                link,
                figure,
                itemBGColor,
                borderColor,
                buttonColor,
                buttonHoverColor,
                subtitleColor,
                logos,
              } = feature

              const target = link.startsWith('/') ? '' : '_blank'

              return (
                <StyledFeaturesItem
                  key={idx}
                  backgroundColor={itemBGColor}
                  borderColor={borderColor}
                  subtitleColor={subtitleColor}
                >
                  <div className="item-content">
                    {needShowLogo && (
                      <div className="item-header">
                        {logos.map((logo, index) => (
                          <img
                            key={index}
                            src={logo}
                            className="item-logo"
                            alt="logo"
                          />
                        ))}
                      </div>
                    )}
                    <h3 className="item-title">{t(`feat${idx}_title`)}</h3>
                    <p className="item-desc">{t(`feat${idx}_desc`)}</p>
                    <StyledFeaturesItemButton
                      target={target}
                      href={link}
                      backgroundColor={buttonColor}
                      hoverColor={buttonHoverColor}
                    >
                      <span
                        data-trackdata={JSON.stringify({
                          title: t('feat${idx}_title'),
                          url: link,
                          index: idx,
                        })}
                        data-trackid="webtl_homepage_explore"
                        onClick={trackElClick}
                      >
                        {t(`feat${idx}_button`)}
                      </span>
                    </StyledFeaturesItemButton>
                  </div>
                  <img src={figure} className="figure" alt={`feature${idx}`} />
                </StyledFeaturesItem>
              )
            })}
          </StyledFeaturesContent>
        ) : (
          <Carousel
            showThumbs={false}
            axis="horizontal"
            showIndicators={true}
            showStatus={false}
            showArrows={false}
            autoPlay={false}
            centerMode
            emulateTouch
            renderIndicator={(onClickHandler, isSelected, index, label) => {
              if (isSelected) {
                return (
                  <li
                    style={{ ...indicatorStyles, background: '#1C1C1E' }}
                    aria-label={`Selected: ${label} ${index + 1}`}
                    title={`Selected: ${label} ${index + 1}`}
                  >
                    {index + 1}
                  </li>
                )
              }
              return (
                <li
                  style={indicatorStyles}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                  title={`${label} ${index + 1}`}
                  aria-label={`${label} ${index + 1}`}
                >
                  {index + 1}
                </li>
              )
            }}
          >
            {features.map((feature, index) => {
              const idx = index + 1
              const needShowLogo = idx === 1 || idx === 2
              const {
                link,
                figure,
                itemBGColor,
                borderColor,
                buttonColor,
                buttonHoverColor,
                subtitleColor,
                logos,
              } = feature

              const target = link.startsWith('/') ? '' : '_blank'

              return (
                <StyledFeaturesItem
                  key={idx}
                  backgroundColor={itemBGColor}
                  borderColor={borderColor}
                  subtitleColor={subtitleColor}
                >
                  <div className="item-content">
                    {needShowLogo && (
                      <div className="item-header">
                        {logos.map((logo, index) => (
                          <img
                            key={index}
                            src={logo}
                            className="item-logo"
                            alt="logo"
                          />
                        ))}
                      </div>
                    )}
                    <h3 className="item-title">{t(`feat${idx}_title`)}</h3>
                    <p className="item-desc">{t(`feat${idx}_desc`)}</p>
                    <StyledFeaturesItemButton
                      target={target}
                      href={link}
                      backgroundColor={buttonColor}
                      hoverColor={buttonHoverColor}
                    >
                      <span>{t(`feat${idx}_button`)}</span>
                    </StyledFeaturesItemButton>
                  </div>
                  <img src={figure} className="figure" alt={`feature${idx}`} />
                </StyledFeaturesItem>
              )
            })}
          </Carousel>
        )}
      </StyledHomeContent>
    </StyledFeatures>
  )
}
